/*
    Only add styles here if they are impossible to add in theme.ts!
*/

/* :root {} */

body {
    font-family: 'Roboto';
}

/* [data-cy] {
    border: 1px solid blue;
} */
